import React, { useState, useRef } from 'react';
import axios from 'axios';
import TempoTapper from './TempoTapper';
import Tooltip from './ToolTip';
import './TABGenerator.css';

function TABGenerator() {
    const [file, setFile] = useState(null);
    const [spectrogramUrl, setSpectrogramUrl] = useState('');
    const audioRef = useRef(null);
    const [audioLoaded, setAudioLoaded] = useState(false);
    const [tempoType, setTempoType] = useState(true);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        const audioUrl = URL.createObjectURL(event.target.files[0]);
        audioRef.current.src = audioUrl;
    };

    const handleUpload = async () => {
        // if (!file) return;
        // const formData = new FormData();
        // formData.append('file', file);

        // const response = await axios.post('http://192.168.0.43:5000/generate_spectrogram', formData, {
        //     responseType: 'blob',
        // });
        // const imageUrl = URL.createObjectURL(response.data);
        // setSpectrogramUrl(imageUrl);
        return;
    };

    const onLoadedMetadata = () => {
        setAudioLoaded(true);
    };

    return (
        <div className='container'>
            <div className='center-column'>
                <h1 className='title'>Guitar TAB Generator</h1>
                <div className='header'><h2>Upload .WAV File <Tooltip message={'Hi there! this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description.'} /></h2></div>
                {/* <p>this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description. this is a sample description.</p> */}
                <div className='upload-container'>
                    <input
                        type='file'
                        accept='audio/wav'
                        onChange={handleFileChange}
                        id='fileInput'
                        className='hiddenInput'
                    />
                    <label htmlFor='fileInput' className='customFileButton'>
                        Choose File
                    </label>
                    {file && (
                        audioLoaded && (
                            <audio controls src={audioRef.current.src} className="audio-player">
                                Your browser does not support the audio element.
                            </audio>
                        )
                    )}
                </div>
                <button
                    onClick={handleUpload}
                    className={`uploadButton ${file ? 'active' : ''}`}
                    disabled={!file}
                >
                    Upload and Generate Spectrogram
                </button>
                <audio ref={audioRef} onLoadedMetadata={onLoadedMetadata} />
                <div className='timing-selector'>
                    <button onClick={() => setTempoType(false)} className={`timingButton ${!tempoType ? 'active' : ''}`}>Choose a Tempo</button>
                    <button onClick={() => setTempoType(true)} className={`timingButton ${tempoType ? 'active' : ''}`}>Mark Measures Manually</button>
                </div>
                {tempoType && (
                    file && (
                        audioLoaded && (
                            spectrogramUrl && (
                                <div className='tempo-tapper-container'>
                                    <TempoTapper spectrogramUrl={spectrogramUrl} file={file} audioRef={audioRef} />
                                    <button className='printButton'>Print</button> {/*Make into separate object eventually*/}
                                </div> 
                            )
                        )
                    )
                )}
            </div>
        </div>
    );
}

export default TABGenerator;
